

// ul {
//   list-style: none; /* Remove default bullets */
// }
ul li {
      line-height: 2;
}

ul li  {
  content: "";
}

.green-dots li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: $primary !important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: .8em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

// body {
//   color:#77838f !important;
// }


.title-fredoka {
  font-family: "Solway" !important;

}

.text-red {
  color:#E73648 !important;
}
.text-pink {
  color:#F3B0CE !important;
}
.text-purple {
  color:#C64793 !important;
}
.text-orange {
  color:#E28A00 !important;
}


/* Setting fade transition and default settings */
body {
 transition: background-color 1s ease;
}

/* panel styles */
.panel {
  /* min height incase content is higher than window height */
  // height: 100vh;

  // display: flex;

  // justify-content: space-around;
   // outline: 10px solid hotpink;
  /* turn above on to see the edge of panels */
}

/* colours */
//
// .color-primary {
//   background:#FAB82B;
//   background-color:#FAB82B;
// }
//
// .color-orange {
//   background:#E28A00;
//   background-color:#E28A00;
// }
//
// .color-pink,
// .bg-pink {
//   background:#F3B0CE;
//   background-color:#F3B0CE;
// }
// .color-purple {
//   background:#C64793;
//   background-color:#C64793;
// }
//
// .color-purple {
//   background:#C64793;
//   background-color:#C64793;
// }
// .color-blue {
//   background:#8BD0E0;
//   background-color:#8BD0E0;
// }




.line-height-title {
  line-height: 1.2em !important;
}


.support-block {
  .btn {

  @extend .btn-block;
}
}


.figure-para {
  /* Set a specific height */
 height: 500px;

 /* Create the parallax scrolling effect */
 background-attachment: fixed;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.parallax-child {
  transform-origin: 0 0;
  transform: translateZ(-122px) scale(3);
}

.js-slide{
  img {
    // width: 100%;
    // height: auto;
  }
}

.slick-center {
  img {
    width: 80%;
    height: auto;
    margin-left:-40px;
  }
}
//
// .container2 {
//
//   scroll-snap-type: y mandatory;
//     overflow-y: scroll;
// height: 100vh;
// }
//
//
// .container2 section {
//   scroll-snap-align: start;
//   transition: background-color 1s ease;
//
//   height: 100vh;
//   display: flex;
// }
//
//
//
// .container2 section:nth-child(1) {
//
// }
//
//
// .container2 section:nth-child(2) {
//
//   background:#E28A00;
//  background-color:#E28A00;
//
// }
//
//
// .container2 section:nth-child(3) {
//   background:#F3B0CE;
//   background-color:#F3B0CE;
//    transition: background-color 1s linear;
// }
//
//
//
// .container2 section:nth-child(4) {
//   background:#C64793;
//   background-color:#C64793;
// }
//
// .container2 section:nth-child(5) {
//
// }
// .container2 section:nth-child(6) {
//   background:#8BD0E0;
// background-color:#8BD0E0;
// }
// .container2 section:nth-child(8) {
//   background:#F3B0CE;
//     background-color:#F3B0CE; transition: background-color 1s linear;
//       height: 33vh !important;
// }

/* ...you get the point */
